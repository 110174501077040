<template>
  <div class="recommend"
       v-loading="loading">
    <div class="top-card-wrap">
      <img :src="highQuality.coverImgUrl"
           class="bg-blur">
      <div class="top-card">
        <div class="img-wrap">
          <img :src="highQuality.coverImgUrl">
        </div>
        <div class="card-content">
          <div class="tag">
            <span><i class="iconfont icon-V"></i>精品歌单</span>
          </div>
          <div class="card-title">{{highQuality.name}}</div>
          <div class="card-info">{{highQuality.description}}</div>
        </div>
      </div>
    </div>
    <div class="tab-bar">
      <ul>
        <li :class="item == tabActive?'tab-item active':'tab-item'"
            v-for="(item) in tabItems"
            :key="item.id"
            @click="changeActive(item)">{{item}}</li>
      </ul>
    </div>
    <ul class="items">
      <li class="item_list"
          v-for="(item, index) in songLists"
          @mouseleave="PlayMove = false"
          @mousemove="PlayMove = index"
          @click="toPlaylistDetail(item.id)"
          :key="item.id">
        <img v-lazy="item.coverImgUrl">
        <div v-show="PlayMove === index">
          <div class="playCount"
               :class="{ songs_top_bottom: PlayMove === index }">
            <i class="iconfont icon-caret-right"></i>
            {{ item.playCount | ellipsisPlayVolume }}
          </div>
          <i class="iconfont play_icon icon-bofang"></i>
        </div>
        <div class="text_name">{{ item.name }}</div>
      </li>
    </ul>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   background
                   :layout="pagi"
                   :total="total"
                   :page-sizes="[10, 15, 30, 50]"
                   :page-size="size"
                   :current-page="page">
    </el-pagination>
  </div>
</template>

<script>
import { highQualityAPI, songListAPI } from '@/api/api'
export default {
  data () {
    return {
      PlayMove: '',
      highQuality: [],
      songLists: [],
      tabActive: '全部',
      tabItems: [
        '全部',
        '欧美',
        '华语',
        '流行',
        '说唱',
        '摇滚',
        '民谣',
        '电子',
        '轻音乐',
        '影视原声',
        'ACG',
        '怀旧'
      ],
      total: 0,
      page: 1,
      loading: true,
      size: 10,
      pagi: 'total, sizes, prev, pager, next, jumper'
    }
  },
  methods: {
    toPlaylistDetail (id) {
      this.$router.push(`/playlist?id=${id}`)
    },
    changeActive (item) {
      this.tabActive = item
      this.page = 1
      this.getHighQuality(item)
      this.getSongLists(item) /* 不调用函数 也可以使用watch监听实现 */
    },
    async getHighQuality (cat = '全部') {
      // 精品歌单
      const params = {
        limit: 1,
        cat
      }
      const { data: res } = await highQualityAPI(params)
      this.highQuality = res.playlists[0]
    },
    async getSongLists (cat = '全部') {
      this.loading = true
      // 歌单列表
      const params = {
        limit: this.size,
        offset: (this.page - 1) * this.size,
        cat
      }
      const { data: res } = await songListAPI(params)
      this.songLists = res.playlists
      this.total = res.total /* 改变总页数 */
      this.loading = false
    },
    handleCurrentChange (page) {
      this.page = page
      this.getSongLists(this.tabActive)
    },
    handleSizeChange (val) {
      this.size = val
      this.getSongLists()
    }
  },
  created () {
    this.getHighQuality()
    this.getSongLists()
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.pagi = 'prev,jumper,next'
    }
  }
}
</script>

<style scoped lang="less">
ul {
  list-style: none;
}

.recommend {
  margin: 0 auto;
  padding: 20px;
}

.top-card-wrap {
  height: 250px;
  padding: 20px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.bg-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(60px);
  z-index: 2;
}

.top-card {
  display: flex;
  position: absolute;
  z-index: 3;
}

.img-wrap {
  width: 200px;
  height: 200px;
}

.img-wrap img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.card-content {
  margin-top: -10px;
  flex: 1;
  padding: 0 20px;
}

.card-title {
  color: #fff;
  margin: 14px 0;
}

.card-info {
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  color: #e3e3e3;
}
.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.items .item_list {
  position: relative;
  top: 0;
  left: 0;
  width: 190px;
  height: 250px;
  margin: 10px 25px 0;
  overflow: hidden;
}
.items .item_list img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer !important;
}
.playCount {
  position: absolute;
  top: -20%;
  right: 0;
  width: 100%;
  padding: 3px 10px;
  color: #ffffff;
  text-align: right;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.01),
    rgba(0, 0, 0, 0.5)
  ) !important;
}
.play_icon {
  position: absolute;
  bottom: 70px;
  right: 15px;
  color: #ff0000;
  font-size: 50px;
}
.li_ {
  display: inline-block;
  margin: 5px 5px;
}

.li_:nth-child(n + 2) {
  margin: 20px 10px;
  &:hover {
    color: #ff0000;
  }
  span {
    border: 1px solid #cccccc;
    padding: 5px;
    margin: 5px 0;
  }
}

.tab-bar {
  margin: 20px 0;
}
.tab-bar ul {
  height: 25px;
  display: flex;
  justify-content: flex-end;
}
.tab-bar ul li {
  margin: 0 10px;
  color: #7c86a0;
  cursor: pointer;
}
.tab-item {
  margin-left: 20px;
  cursor: pointer;
  font-size: 15px;
  color: grey;
}
.active {
  color: #ff0000 !important;
}
.tag {
  margin: 20px 0;
}
.tag span {
  padding: 5px;
  color: #feaf39;
  border-radius: 10px;
  border: 2px solid #feaf39;
}
.tag span i {
  font-weight: 600;
  font-size: 18px;
  padding: 0 5px;
}
.text_name {
  line-height: 20px;
  margin-top: 10px;
}

@media screen and (max-width: 479px) {
  .recommend,
  .card-content {
    padding: 5px;
  }
  .recommend .top-card-wrap {
    height: 20vh;
    padding: 1vw;
  }
  .recommend .img-wrap {
    margin-top: 9vw;
    width: 18vw;
    height: 18vw;
  }
  .top-card {
    height: 20vh;
  }
  .tag {
    margin: 3vw 0 4vw 0;
    height: 10px;
  }
  .tag span {
    padding: 2px 3px;
    font-size: 10px;
  }
  .tag span i {
    font-size: 10px;
    padding: 0 5px;
  }
  .card-content {
    width: auto;
  }
  .card-title {
    margin: 3px 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
  }
  .card-info {
    font-size: 12px;
    line-height: 14px;
    margin-top: 2vw;
    -webkit-line-clamp: 6;
  }
  .tab-bar ul li {
    height: 16vw;
    width: 4vw;
    margin: 0 1vw;
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .tab-item {
    margin-left: 10px;
    font-size: 13px;
  }
  .items .item_list {
    width: 30vw;
    height: 40vw;
    margin: 3vw 0;
    overflow: hidden;
  }
  .text_name {
    line-height: 15px;
    font-size: 12px;
    margin-top: 5px;
  }
  .items {
    justify-content: space-evenly;
  }
  /deep/ .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .el-pagination {
    margin: 2vw 0;
  }
}
</style>
